<template>
  <b-container>
    <b-row class="pb-2 small">
      <b-col cols="12">
        <span v-for="n in news" :key="n.title">
          <b>{{n.title}}</b>
          <span v-html="n.text"></span>
        </span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data: function () {
    return {
      news: []
    }
  },
  created() {
    let url = "/api/data/news.json?" + new Date().getTime();
    const promises = [url].map(url => fetch(url).then(r => r.json()));
    return Promise.all(promises).then(r => this.news = r[0]);
  }
}
</script>
